export const education = [
  {
    eduDate: "2009",
    eduTitle: "Batchelor of Technology with Honors",
    eduIns: "Indian Institute of Information Technology",
    eduDesc: "8.75 CGPA",
  },
  {
    eduDate: "2004",
    eduTitle: "Higher Secondary",
    eduIns: "Ratnam Junior College",
    eduDesc: "93.6%",
  },
  {
    eduDate: "2002",
    eduTitle: "Secondary",
    eduIns: "Kurnool Public School",
    eduDesc: "90%",
  }
];
