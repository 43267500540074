import ExpImg1 from "../assets/images/resources/aspire.png";
import ExpImg2 from "../assets/images/resources/deloitte.png";
import ExpImg3 from "../assets/images/resources/uhg.png";
import ExpImg4 from "../assets/images/resources/motivity.png";
import ExpImg5 from "../assets/images/resources/microsoft.png";

export const workExp = [
  {
    expCount: "1",
    expDate: "2021 - Present",
    expImg: ExpImg1,
    expTitle: "INTALEQ - Aspire Zone Foundation",
    expSubTitle: "Solution Architect IT Applications",
    expDesc: "",
  },
  {
    expCount: "2",
    expDate: "2021 - 2021",
    expImg: ExpImg2,
    expTitle: "Deloitte",
    expSubTitle: "Executive Manager - Solution Architecture",
    expDesc: ""
  },
  {
    expCount: "3",
    expDate: "2017 - 2021",
    expImg: ExpImg3,
    expTitle: "United Health Group",
    expSubTitle: "Senior Software Lead Engineer / Architect",
    expDesc: ""
  },
  {
    expCount: "4",
    expDate: "2012 - 2017",
    expImg: ExpImg4,
    expTitle: "Motivity Labs",
    expSubTitle: "Technical Lead",
    expDesc:""
  },
  {
    expCount: "5",
    expDate: "2009 - 2011",
    expImg: ExpImg5,
    expTitle: "Microsoft (contracted via MAQ Software)",
    expSubTitle: "Senior Software Engineer",
    expDesc:""
  },
];
